import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faPython,
  faDocker,
  faAws,
  faMicrosoft,
  faWordpress,
  faGoogle,
  faNode,
  faJs,
  faReact,
  faNpm,
  faGitAlt,
  faEthereum,
  faCss3Alt,
  faPhp,
  faLinkedin,
  faGithub,
  faStripe,
  faCcMastercard,
  faAngular,
  faSpotify,
  faHtml5
} from "@fortawesome/free-brands-svg-icons";
import {
  faBriefcase,
  faGraduationCap,
  faTimes,
  faDownload,
  faDatabase,
  faServer,
  faVrCardboard,
  faCodeBranch,
  faExternalLinkAlt,
  faBars,
  faGlobe,
  faWaveSquare,
  faEnvelope,
  faPhone
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faDownload,
  faWordpress,
  faLinkedin,
  faGithub,
  faPython,
  faDocker,
  faAws,
  faMicrosoft,
  faGoogle,
  faNode,
  faJs,
  faReact,
  faNpm,
  faGitAlt,
  faTimes,
  faEthereum,
  faCss3Alt,
  faPhp,
  faDatabase,
  faServer,
  faBriefcase,
  faGraduationCap,
  faVrCardboard,
  faCodeBranch,
  faExternalLinkAlt,
  faBars,
  faStripe,
  faGlobe,
  faWaveSquare,
  faCcMastercard,
  faAngular,
  faSpotify,
  faHtml5,
  faEnvelope,
  faPhone
);
