import React, { useState, useEffect } from "react";
import profilePic from "../../assets/ProfilePic.jpg";
import ResumePDF from "../../assets/Resume.pdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ProfileSection.css";

const fullNames = ["Aadar Gupta", "Software Engineer"];
const greetings = ["Hello, My name is", "I am a"];

function ProfileSection() {
  const [displayedName, setDisplayedName] = useState("");
  const [greeting, setGreeting] = useState("");
  const [typing, setTyping] = useState(true);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setGreeting(greetings[index]);
    if (typing) {
      if (displayedName.length < fullNames[index].length) {
        setTimeout(() => {
          setDisplayedName(
            fullNames[index].substring(0, displayedName.length + 1)
          );
        }, 200);
      } else {
        setTimeout(() => {
          setTyping(false);
        }, 3000);
      }
    } else {
      setTimeout(() => {
        setDisplayedName(displayedName.substring(0, displayedName.length - 1));
        if (displayedName.length === 0) {
          setTyping(true);
          setIndex((index + 1) % fullNames.length);
        }
      }, 150);
    }
  }, [displayedName, typing, index]);

  const handleDownloadCV = () => {
    window.open(ResumePDF);
  };

  return (
    <section id="profile" className="profile">
      <div className="profile-container">
        <div className="profile-pic-container">
          <img src={profilePic} alt="Profile" />
        </div>
        <div className="profile-text">
          <p className="profile-text-greeting">{greeting}</p>
          <h1 className="profile-name">
            {displayedName}
            <span className="cursor">|</span>{" "}
          </h1>
          <button
            className="profile-btn download-cv"
            onClick={handleDownloadCV}
          >
            <FontAwesomeIcon
              icon="fa-solid fa-download"
              size="1x"
              style={{ background: "transparent", marginRight: "8px" }}
              className="download-btn"
            />
            Resume
          </button>
          <div className="profile-socials">
            <a
              href="https://www.linkedin.com/in/aadar-gupta/"
              target="_blank"
              rel="noopener noreferrer"
              className="profile-social-link"
            >
              <FontAwesomeIcon
                icon="fa-brands fa-linkedin"
                size="3x"
                style={{ background: "transparent" }}
                className="social-link"
              />
            </a>
            <a
              href="https://github.com/AadarGupta"
              target="_blank"
              rel="noopener noreferrer"
              className="profile-social-link"
            >
              <FontAwesomeIcon
                icon="fa-brands fa-github"
                size="3x"
                style={{ background: "transparent" }}
                className="social-link"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProfileSection;
