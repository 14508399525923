import React from "react";
import Navbar from "./components/Navbar/Navbar";
import ProfileSection from "./components/Profile/ProfileSection";
import AboutSection from "./components/About/AboutSection";
import ExperienceSection from "./components/Experience/ExperienceSection";
import ProjectsSection from "./components/Projects/ProjectsSection";
// eslint-disable-next-line
import FontAwesomeIcons from "./FontAwesomeIcons";
import "./App.css";
import ContactSection from "./components/Contact/ContactSection";

function App() {
  return (
    <div>
      <Navbar />
      <div>
        <ProfileSection />
        <AboutSection />
        <ExperienceSection />
        <ProjectsSection />
        <ContactSection />
      </div>
    </div>
  );
}

export default App;
