import React from "react";
import "./ContactSection.css";
import AboutCard from "../AboutCard/AboutCard";
import { CONTACT } from "../../constants/contact";

function ContactSection() {
  return (
    <section id="contact" className="contact-section">
      <div className="contact-section-container">
        <h1 className="contact-section-title">Contact</h1>
        <div className="contact-cards">
          {CONTACT.map((contact, index) => (
            <a href={contact.link}>
                <AboutCard className="contact-card" icon={contact.icon} mainText={contact.title} text={contact.text} key={index} />
            </a>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ContactSection;
