import mawariLogo from "../assets/experience/mawari.png";
import microArtLogo from "../assets/experience/microart.png";
import ratehubLogo from "../assets/experience/ratehub.png";
import titanFileLogo from "../assets/experience/titanfile.svg";

import mawariImage from "../assets/experience/image/mawari.jpeg";
import microArtImage from "../assets/experience/image/microart.jpg";
import ratehubImage from "../assets/experience/image/ratehub.png";
import titanFileImage from "../assets/experience/image/titanfile.png";

export const EXPERIENCE = [
  {
    company: "Mawari",
    start: "Jan",
    end: "Apr '24",
    logo: mawariLogo,
    image: mawariImage,
    role: "Platform Back-End Developer",
    tasks: [
      "Debugging Docker Containers",
      "Initializing Virtual Environments",
      "Implement Validation Process (Strategy Design Pattern) for Extended Reality Streams",
      "Refactored Cost Structure for Cloud Services",
      "HardHat tests for Solidity Smart Contracts",
      "Validation of API Endpoints",
      "Develop Project File Script in Python",
    ],
    skills: [
      "Python",
      "Docker",
      "Solidity",
      "Virtual Reality",
      "AWS",
      "Google Cloud",
      "Azure",
      "Git",
    ],
  },
  {
    company: "MicroArt Services",
    start: "May",
    end: "Aug '23",
    logo: microArtLogo,
    image: microArtImage,
    role: "Software Engineer",
    tasks: [
      "Develop Query to Extract Text From Image",
      "Implement Full-Text Search",
      "Populate Database Through Web Scraping",
      "Create Front-End Features",
      "Debug Bluetooth Low Energy Devices",
    ],
    skills: [
      "JavaScript",
      "React Native",
      "CSS",
      "NPM",
      "Node",
      "Google Cloud",
      "SQL",
      "Git",
    ],
  },
  {
    company: "RateHub",
    start: "May",
    end: "Aug '22",
    logo: ratehubLogo,
    image: ratehubImage,
    role: "Front-End Engineer",
    tasks: [
      "Refactored Typography Elements",
      "Integrated Cities API into Front-End",
      "Created & Modified Web Components",
      "Developed Storyblocks",
    ],
    skills: ["JavaScript", "React", "WordPress", "CSS", "HTML", "Git"],
  },
  {
    company: "RateHub",
    start: "Jan",
    end: "Apr '22",
    logo: ratehubLogo,
    image: ratehubImage,
    role: "Platform DevOps Engineer",
    tasks: [
      "Enhanced Task Manager",
      "Upgraded Docker Containers",
      "Maintained Back-End Infrastructure",
      "Improved Cities API Back-End",
    ],
    skills: ["Node", "JavaScript", "NPM", "Docker", "PHP", "MongoDB", "Git"],
  },
  {
    company: "TitanFile",
    start: "May",
    end: "Aug '21",
    logo: titanFileLogo,
    image: titanFileImage,
    role: "Software Engineer",
    tasks: [
      "Upgraded Profile Avatars to Dynamic SVGs",
      "Implemented Intra-channel Search Feature",
      "Improved Client Side Encryption",
      "Fixed Bugs On Back-End",
      "Performed End-to-End Tests",
    ],
    skills: ["Python", "JavaScript", "Node", "NPM", "MariaDB", "CSS", "HTML", "Git"],
  },
];
