import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../assets/Logo.png";
import "./Navbar.css";

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="nav-container">
      <nav className="navbar">
        <div className="logo">
          <img src={Logo} alt="AG" />
        </div>
        <ul className={`nav-links ${isMenuOpen ? "nav-links-mobile" : ""}`}>
          <li onClick={toggleMenu}>
            <a href="#profile">Home</a>
          </li>
          <li onClick={toggleMenu}>
            <a href="#about">About</a>
          </li>
          <li onClick={toggleMenu}>
            <a href="#experience">Experience</a>
          </li>
          <li onClick={toggleMenu}>
            <a href="#projects">Projects</a>
          </li>
          <li onClick={toggleMenu}>
            <a href="#contact">Contact</a>
          </li>
          <li onClick={toggleMenu}>
            <a href="https://radar-music.ca">RadaR</a>
          </li>
        </ul>
        <div className="hamburger" onClick={toggleMenu}>
          <FontAwesomeIcon
                icon={isMenuOpen ? 'fa-solid fa-times' : 'fa-solid fa-bars'}
                size="1x"
                style={{ background: "transparent" }}
          /> 
        </div>
      </nav>
      {isMenuOpen && (
        <ul className="nav-links nav-links-mobile-open">
          <li onClick={toggleMenu}>
            <a href="#profile">Home</a>
          </li>
          <li onClick={toggleMenu}>
            <a href="#about">About</a>
          </li>
          <li onClick={toggleMenu}>
            <a href="#experience">Experience</a>
          </li>
          <li onClick={toggleMenu}>
            <a href="#projects">Projects</a>
          </li>
          <li onClick={toggleMenu}>
            <a href="#contact">Contact</a>
          </li>
          <li onClick={toggleMenu}>
            <a href="https://radar-music.ca">RadaR</a>
          </li>
        </ul>
      )}
    </div>
  );
}

export default Navbar;
