import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SKILLS } from "../../constants/skills";
import "./ExperienceCard.css";

function ExperienceCard({ experience }) {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const toggleOverlay = () => {
    setIsOverlayVisible(!isOverlayVisible);
  };

  const renderSkillsIcons = (skills) => {
    return skills.map((skill) => {
      const icon = SKILLS[skill];
      if (icon) {
        return (
          <FontAwesomeIcon
            icon={icon}
            key={skill}
            title={skill}
            size="1x"
            className="icon-check"
          />
        );
      }
      return null;
    });
  };

  return (
    <div className="experience-container">
      <div className="experience-card" onClick={toggleOverlay}>
        <div className="company-logo-container">
          <img
            src={experience.logo}
            alt={`${experience.company} Logo`}
            className="company-logo"
          />
        </div>

        <div className="company-image-container">
          <img
            src={experience.image}
            alt={`${experience.company}`}
            className="company-image"
          />
        </div>

        <div className="experience-content">
          <div className="experience-company">
            <h3>{experience.role.toUpperCase()}</h3>
            <p>{`${experience.start} - ${experience.end}`}</p>
          </div>
          <div className="skills-icons">
            {renderSkillsIcons(experience.skills)}
          </div>
        </div>
      </div>

      {isOverlayVisible && (
        <div className="overlay" onClick={toggleOverlay}>
          <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={toggleOverlay}>
              <FontAwesomeIcon icon="fa-solid fa-times" size="2x" />
            </button>
            <img
              src={experience.logo}
              alt={`${experience.company} logo`}
              className="company-logo"
            />
            <div className="overlay-split">
              <div className="overlay-left">
                <h2 className="overlay-title">Responsibilities</h2>
                <ul className="tasks">
                  {experience.tasks.map((task, index) => (
                    <li key={index}>{task}</li>
                  ))}
                </ul>
              </div>
              <div className="overlay-right">
                <h2 className="overlay-title">Skills</h2>
                <ul className="skills">
                  {experience.skills.map((skill, index) => (
                    <div className="overlay-skill">
                      <li key={index}>{skill}</li>
                    </div>
                  ))}
                </ul>
              </div>
            </div>
            <div className="skills-icons">
              {renderSkillsIcons(experience.skills)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ExperienceCard;
