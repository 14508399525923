import React from "react";
import "./ExperienceSection.css";
import { EXPERIENCE } from "../../constants/experience";
import ExperienceCard from "../ExperienceCard/ExperienceCard";

function ExperienceSection() {
  return (
    <section id="experience" className="experience-section">
      <div className="experience-section-container">
        <h1 className="experience-section-title">Experience</h1>
        <div className="experience-cards">
          {EXPERIENCE.map((exp, index) => (
            <ExperienceCard experience={exp} key={index} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default ExperienceSection;
