import React from "react";
import "./AboutSection.css";
import AboutCard from "../AboutCard/AboutCard";
import ResumePDF from "../../assets/Resume.pdf";
import TranscriptPDF from "../../assets/Transcript.pdf";

function AboutSection() {
  const clickExperience = () => {
    window.open(ResumePDF);
  };

  const clickEducation = () => {
    window.open(TranscriptPDF);
  };

  return (
    <section id="about" className="about-section">
      <div className="about-section-container">
        <h1 className="about-section-title">About Me</h1>
        <div className="about-section-grid">
          <AboutCard
            icon="fa-solid fa-briefcase"
            title="Experience"
            mainText="20+ Months"
            text="Software Development"
            onClick={clickExperience}
          />

          <AboutCard
            icon="fa-solid fa-graduation-cap"
            title="Education"
            mainText="Computer Science"
            text="University of Waterloo"
            onClick={clickEducation}
          />
        </div>

        <p className="about-section-description">
          As a senior Computer Science student at the University of Waterloo,
          I've dived deep into the intricacies of algorithms, data structures,
          and software design through challenging courses like CS 240, CS 341,
          and CS 349, among others. My academic journey has been complemented by
          diverse co-op experiences across companies like TitanFile, Ratehub,
          MicroArt, and Mawari Technologies, where I've played roles ranging
          from software to backend platform engineering. These stints have not
          only sharpened my technical expertise but also my adaptability to
          different tech environments.
          <br /> <br />
          Beyond the confines of coursework and co-op terms, my enthusiasm lies
          at the intersection of blockchain and music technology—a space where I
          believe the future of tech is headed. Blockchain's potential to
          reinvent our digital landscape fascinates me, and I'm equally
          passionate about how music technology can reshape the arts. Under the
          alias RadaR, I channel my creativity into producing, songwriting, and
          releasing music. This personal endeavor is more than an artistic
          outlet; it's a technical playground where I experiment with the latest
          in sound engineering and digital distribution, envisioning new ways to
          fuse my technical background with my artistic aspirations.
        </p>
      </div>
    </section>
  );
}

export default AboutSection;
