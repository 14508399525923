import createCraftImg from "../assets/projects/create-craft.svg";
import pitchItImg from "../assets/projects/pitch-it.png";
import ICSImg from "../assets/projects/india-canada-society.svg";
import radarImg from "../assets/projects/radar.png";
import muzikMyndImg from "../assets/projects/muzik-mynd.png";
import friendMeImg from "../assets/projects/friend-me.png";

export const PROJECTS = [
  {
    name: "Create Craft",
    tagline: "Create Your Art, Craft Perfection",
    link: "https://create-craft.com",
    github: "",
    image: createCraftImg,
    description:
      "Create Craft is a project that I am currently working on, designed as a project management platform specifically for artists, musicians and individuals in the creator industry.",
    skills: ["JavaScript", "NPM", "API", "Nginx", "React", "CSS", "HTML", "Node", "Git"]
  },
  {
    name: "Pitch-It",
    tagline: "If you can't hit your pitch, PitchIt",
    link: "https://pitch-it.app",
    github: "https://github.com/AadarGupta/pitch-it/tree/prod",
    image: pitchItImg,
    description:
      "Pitch-It is a web application that was developed for CS 489 (Computational Audio) as a final project. However, the application itself is live and is available to the public for use.",
    skills: ["Python", "API", "Computational Audio", "Nginx", "Docker", "JavaScript", "NPM", "React", "CSS", "HTML", "Node", "Git"]
  },
  {
    name: "India Canada Society",
    tagline: "Volunteer as Web Developer",
    link: "https://indiacanadasociety.ca",
    github: "https://github.com/AadarGupta/india-canada-society",
    image: ICSImg,
    description:
      "India Canada Society is a non-profit organization, which connects people of the Indian diaspora (residing in Canada) to one another, by hosting events and cultural gatherings.",
    skills: ["Nginx", "JavaScript", "NPM", "Stripe", "Payments Processing", "API", "React", "CSS", "HTML", "Node", "Git"]
  },
  {
    name: "RadaR",
    tagline: "Personal Website",
    link: "https://radar-music.ca",
    github: "https://github.com/AadarGupta/radar-music",
    image: radarImg,
    description:
      "As an artist, having a platform and a way to organize your art is crucial to growth. This website is designed as a way to market my music and my identity as a brand.",
    skills: ["Nginx", "JavaScript", "NPM", "Stripe", "API", "React", "CSS", "HTML", "Node", "Git"]
  },
  {
    name: "Muzik Mynd",
    tagline: "Get your Mynd on Muzik",
    link: "",
    github: "https://github.com/AadarGupta/muzik-mynd",
    image: muzikMyndImg,
    description:
      "Muzik Mynd is a platform that allows a user to login with their Spotify credentials and find less popular artists, songs and playlist recommendations based on the user's music taste. Seek for new music!",
    skills: ["JavaScript", "NPM", "Spotify API", "API", "React", "CSS", "HTML", "Node", "Git"]
  },
  {
    name: "FriendMe",
    tagline: "Coding Assessment",
    link: "",
    github: "https://github.com/AadarGupta/friend-me",
    image: friendMeImg,
    description:
      "FriendMe is an Angular web application, which displays the contacts of a user and uses pagination to cache the previously loaded contacts.",
    skills: ["Angular", "JavaScript", "NPM", "API", "React", "CSS", "Node", "Git"]
  },
];
