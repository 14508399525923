export const CONTACT = [
    {
        icon: "fa-solid fa-envelope",
        title: "EMAIL ME",
        text: "a362gupt@uwaterloo.ca",
        link: "mailto:a362gupt@uwaterloo.ca",
    },
    {
        icon: "fa-solid fa-phone",
        title: "PHONE",
        text: "(905) 902-0476",
        link: "tel:905-902-0476",
    },
    {
        icon: "fa-brands fa-linkedin",
        title: "CONNECT",
        text: "Aadar Gupta",
        link: "https://www.linkedin.com/in/aadar-gupta/",
    },
    {
        icon: "fa-brands fa-github",
        title: "CODE WITH ME",
        text: "Aadar Gupta",
        link: "https://github.com/AadarGupta",
    },
]