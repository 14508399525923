import React, {useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ProjectCard.css";
import { SKILLS } from "../../constants/skills";

function ProjectCard({ project }) {

  const [showDescription, setDescription] = useState(false);

  const toggleDescription = () => {
    setDescription(!showDescription);
  };

  const renderSkillsIcons = (skills) => {
    return skills.map((skill, index) => {
      const icon = SKILLS[skill]; // Ensure SKILLS maps skill strings to FontAwesome icon objects
      if (icon) {
        return (
          <FontAwesomeIcon
            icon={icon}
            key={index}
            title={skill}
            size="1x"
            className="icon-check"
          />
        );
      }
      return null;
    });
  };

  return (
    <div className="project-card" onClick={toggleDescription}>
          <div className="project-info">
            <h3>{project.name.toUpperCase()}</h3>
            <p className="project-tagline">{project.tagline}</p>
          </div>
          <div className="project-spacing-container">
            {
              !showDescription 
              ? (
                project.image && (
                  <div className="project-image-container">
                    <img
                      src={project.image}
                      alt={project.name}
                      className="project-image"
                    />
                  </div>
                )
              ) : (
                <p className="project-description">{project.description}</p>
              )
            }
          
            <div className="project-buttons">
              <div class="disabled-container" className={`${!project.github && "button-disabled"}`}>
                <a
                  href={project.github}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`button github ${
                    !project.github && "disabled"
                  }`}>
                  <FontAwesomeIcon icon="fa-brands fa-github" /> GitHub
                </a>
              </div>
                
              <div class="disabled-container" className={`${!project.link && "button-disabled"}`}>
                <a
                  href={project.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`button project-link ${
                    !project.link && "disabled"
                  }`}
                >
                  <FontAwesomeIcon icon="fa-s fa-external-link-alt" /> Live
                </a>
              </div>
              
            </div>
          </div>
          <div className="skills-icons">
              {renderSkillsIcons(project.skills || [])}
          </div>
        </div>
  );
}

export default ProjectCard;
