import React from "react";
import "./ProjectsSection.css";
import ProjectCard from "../ProjectCard/ProjectCard";
import { PROJECTS } from "../../constants/projects";

function ProjectsSection() {
  return (
    <section id="projects" className="projects-section">
      <div className="projects-section-container">
        <h1 className="projects-section-title">Projects</h1>
        <div className="projects-cards">
          {PROJECTS.map((project, index) => (
            <ProjectCard project={project} key={index} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default ProjectsSection;
