import React from "react";
import "./AboutCard.css"; // Ensure this import points to the correct path of your CSS file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AboutCard = ({ icon, title, mainText, text, onClick }) => {
  return (
    <div className="about-card" onClick={onClick}>
      <div className="about-card-icon">
        <FontAwesomeIcon icon={icon} className="fa-icon" size="2x" />
      </div>
      <div className="about-card-content">
        {title && <h2 className="about-card-title">{title}</h2>}
        {mainText && <h3 className="about-card-main-text">{mainText}</h3>}
        {text && <p className="about-card-text">{text}</p>}
      </div>
    </div>
  );
};

export default AboutCard;
