export const SKILLS = {
  Python: "fa-brands fa-python",
  Solidity: "fa-brands  fa-ethereum",
  Docker: "fa-brands fa-docker",
  "Google Cloud": "fa-brands fa-google",
  AWS: "fa-brands fa-aws",
  Azure: "fa-brands fa-microsoft",
  Git: "fa-solid fa-code-branch",
  SQL: "fa-solid fa-database",
  MongoDB: "fa-solid fa-database",
  MariaDB: "fa-solid fa-database",
  Node: "fa-brands fa-node",
  JavaScript: "fa-brands fa-js",
  NPM: "fa-brands fa-npm",
  React: "fa-brands fa-react",
  "React Native": "fa-brands fa-react",
  "Virtual Reality": "fa-solid fa-vr-cardboard",
  WordPress: "fa-brands fa-wordpress",
  HTML: "fa-brands fa-html5",
  CSS: "fa-brands fa-css3-alt",
  PHP: "fa-brands fa-php",
  Gunicorn: "fa-solid fa-server",
  Nginx: "fa-solid fa-server",
  Stripe: "fa-brands fa-stripe",
  API: "fa-solid fa-globe",
  "Computational Audio": "fa-solid fa-wave-square",
  "Payments Procesing": "fa-brands fa-cc-mastercard",
  Angular: "fa-brands fa-angular",
  "Spotify API": "fa-brands fa-spotify"
};
